.transaction {
    padding-top: 20px;
    margin-bottom: 5px;
    div {
        font-size: 16px;
        &.status {
            text-align: center;
            font-weight: bold;
            font-size: 14px;
            color: #31364C;
            span {
              padding: 2px 6px;
            }
        }
        &.refresh {
            text-align: right;
            color: #07c0ca;
        }
        &.name {
            text-align: center;
            margin-top: 16px;
            font-weight: bold;
            color: #4F4F4F;
        }
        &.amount {
            text-align: center;
            margin-top: 8px;
            font-weight: normal;
            color: #000000;            
        }
        &.date {
            text-align: center;
            margin-top: 8px;
            font-weight: normal;
            color: #787E9C;
        }
        &.details {
            padding-top: 16px;
            border-bottom: 1px solid #D6D8E1;
        }
    }
}

.icon {
    height: 16px;
    width: 16px;
    margin-top: -3px;
}

.image {
    display: block;
    height: 64px;
    margin-top: 30px;
    margin-bottom: 18px;
    margin-left: auto;
    margin-right: auto;
}

.modalImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

.modalStatus {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #31364C;
}

.modalParagraph {
    text-align: center;
    margin-top: 15px;
    margin-left: 30px;
    margin-right: 30px;
}

.back {
    color: #07c0ca;
    margin-top: 14px;
    font-size: 48px;
    position: absolute;
    float: left;
    cursor: pointer;
}

.refund {
    margin-top: 24px;
    color: #07c0ca;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;    
}

.button {
    margin-top: 16px !important;
    border-radius: 2px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    box-sizing: border-box !important;
    box-shadow: none !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;    
}
  
.buttonDisabled {
    @extend .button;
    border: 2px solid #d6d8e1 !important;
    background: #d6d8e1 !important;
    color: #FFFFFF !important;
}
  
.buttonEnabled {
    @extend .button;
    border: 2px solid #07C0CA !important;
    background: #FFFFFF !important;
    color: #07C0CA !important;
}

.reminder {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #828282;
    margin: 16px;
}
  