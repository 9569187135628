.title {
    font-size: 24px;
    margin-top: 25px;
    font-weight: bold;
    text-align: center;
}
  
.image {
    margin: 50px auto 0 auto;
    display: block;
}

.button {
  border-radius: 2px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  margin: 0px !important;
  background-color: #07c0ca !important;
}

.description {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #52576F;
  margin-top: 42px;
  margin-bottom: 30px;
}

.button:hover {
  background-color: #006f7b !important;
  box-shadow: none !important;
}

.consumerPortalLink {
  display: block;
  margin: 16px 0;
}
  
