.descrWrapper {
  display: grid;
  grid-template-columns: 20px 10fr 11fr;
}
.creditCard {
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 3;
}
.approved {
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}
.approvedValue {
  grid-column-start: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}
.requested {
  grid-column-start: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}
.requestedValue {
  grid-column-start: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}
.qualified{
  grid-column-start: 2;
  grid-row-start: 3;
  grid-row-end: 4;
}
.qualifiedValue{
  grid-column-start: 3;
  grid-row-start: 3;
  grid-row-end: 4;
}

.netProceeds{
  grid-column-start: 2;
  grid-row-start: 4;
  grid-row-end: 5;
}
.netProceedsValue{
  grid-column-start: 3;
  grid-row-start: 4;
  grid-row-end: 5;
}
.refunds{
  grid-column: 1 / 2;
  padding-left: 20px;
}
.refundsRoot{
  display: grid;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 5;
  grid-row-end: 6;
  grid-template-columns: auto auto auto;
}
.refundsValue{
  grid-column-start: 3;
  text-align: right;
  padding-right: 2px;
}

.terms{
  grid-column-start: 2;
  grid-row-start: 6;
  grid-row-end: 7;
}

.termsValue{
  grid-column-start: 3;
  grid-row-start: 6;
  grid-row-end: 7;
}

.mdrs{
  grid-column-start: 2;
  grid-row-start: 7;
  grid-row-end: 8;
}

.mdrsValue{
  grid-column-start: 3;
  grid-row-start: 7;
  grid-row-end: 8;
  white-space: pre-line;
}

.dateRange {
  grid-column-start: 1;
  grid-row-start: 8;
  grid-row-end: 9;
}

.expires{
  grid-column-start: 2;
  grid-row-start: 8;
  grid-row-end: 9;
}