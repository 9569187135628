.image {
  display: block;
  margin: 15px auto 30px;
}

.innerText {
  text-align: center;
  margin-bottom: 54px;
  margin-top: 30px;

  span {
    cursor: pointer;

    b {
      color: #00c3cc;
    }
  }
}
