.accounts {
    margin-top: 14px;
}
.merchant {
    font-size: 14px;
    font-weight: bold;
}
.user {
    font-size: 12px;
}
