.content {
  margin-bottom: 25px;
}

.error_banner_wrapper {
  margin-bottom: 30px;
}

.header {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.27px;
    color: #31364C;
  }
  .subtitle {
    margin-top: 15px;
    color: #52576F;
    font-size: 14px;
    overflow: hidden;
  }
}

.tip {
  margin-top: 15px;
  font-size: 12px;
  color: #52576F;
  text-align: center;
  span {
    font-size: 15px;
    margin-right: 6px;
    vertical-align: text-bottom;
    color: #0aced9;
  }
}

.text_button {
  font-size: 14px !important;
  font-weight: bold !important;
  background-color: #ffffff !important;
  text-align: center;
  cursor: pointer;
  color: #07c0ca !important;
}

.modal_text {
  text-align: center;

  .logo {
    margin-left: auto;
    margin-right: auto;
    margin-top: -15px;
    margin-bottom: 20px;
  }

  h4 {
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0.27px;
    margin-bottom: 15px;
    color: #31364c;
  }

  div {
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    color: #52576F;
    line-height: 140%;
    span {
      color: #0aced9;
    }
  }
}

.subscription {
  display: grid;
  margin-bottom: 30px;
  padding-left: 5px;
  max-height: 24px;
  grid-gap: 10px;

  .subscription_checkbox {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .subscription_text {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    font-size: 10px;
    line-height: 150%;
  }
}
