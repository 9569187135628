.subtitle {
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.image {
  margin: 0 auto 0 auto;
  display: block;
}

.message {
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #52576F;
  margin-top: 30px;
  margin-bottom: 15px;
}

.button {
  border-radius: 2px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  margin: 0 !important;
}

.buttonEnabled {
  @extend .button;
  background-color: #07c0ca !important;
}

button.buttonEnabled:hover {
  background-color: #006f7b !important;
  box-shadow: none !important;
}
