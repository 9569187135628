.text_button {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    background-color: #ffffff !important;
    text-align: center;
    cursor: pointer;
    color: #07c0ca !important;
}

div.text_button:hover {
    color: #006f7b !important;
}

.inputs {
    padding: 0;
    input {
        font-size: 20px;
    }
}

.button {
    border-radius: 2px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    margin: 0px !important;
}

.buttonDisabled {
    @extend .button;
    background-color: #d6d8e1 !important;
}

.buttonEnabled {
    @extend .button;
    background-color: #07c0ca !important;
}

button.buttonEnabled:hover {
    background-color: #006f7b !important;
    box-shadow: none !important;
}

div.button:hover {
    color: #006f7b !important;
}

.message {
    font-size: 14px;
    line-height: 1.64;
    letter-spacing: -0.3px;
    text-align: center;
    color: #787e9c;
    padding: 15px;
}

.pinError {
    font-size: 14px;
    text-align: center;
    color: #FE6E65;
}

.digit {
    input {
        text-align: center !important;
    }
}
