.title {
    font-size: 18px;
    margin-top: 25px;
    font-weight: bold;
    text-align: center;
}

.subtitle {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: -0.34px;
    text-align: center;
    color: #787e9c;
    margin-top: 15px;
    margin-bottom: 15px;
  
    b {
      font-weight: bold;
    }
  
    a {
      font-weight: 600;
      color: #07c0ca;
    }
}
  
.image {
    margin: 30px auto 30px auto;
    display: block;
}
  
