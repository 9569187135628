.header {
  margin-right: -30px !important;
  margin-left: -30px !important;
  padding: 16px 15px 20px 15px;
  .back {
    font-size: 24px;
    max-height: 20px;
    color: #07C0CA;
    cursor: pointer;
  }
  .refresh {
    text-align: right;
    color: #07C0CA;
    span {
      font-size: 24px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}

.name {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.27px;
  color: #31364C;
}

.amount {
  margin-top: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #52576F;
}

.status {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.337647px;
  color: #31364C;
  span {
    padding: 2px 6px;
  }
}

.icon {
  height: 16px;
  margin-top: -3px;
  cursor: pointer;
}

.image {
  display: block;
  height: 64px;
  margin: 30px auto 18px;
}

.date {
  margin-top: 5px;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #52576F;
}

.modalImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.modalStatus {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #31364C;
}

.modalParagraph {
  text-align: center;
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
}
