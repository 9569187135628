.subtitle {
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.nextPage {
  color: #07C0CA;
  margin-top: 30px;
  font-weight: bold;
  margin-left: 72px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  max-width: 170px;
  i {
    display: block;
  }
}

.nextPage:hover {
  color: #006f7b;
}

.noTransactionsImage {
  display: block;
  margin: 17px auto;
}

.noTransactions {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: block;
  text-align: center;
  color: #31364C;
}

.noTransactionsHint {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  display: block;
  text-align: center;
  color: #787E9C;
}
