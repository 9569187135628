.header {
    border-bottom: 1px solid #CECECE;
    margin-right: -30px !important;
    margin-left: -30px !important;
    padding: 16px 15px 20px 15px;
    .close {
        font-size: 24px;
        max-height: 20px;
        color: #07C0CA;
        cursor: pointer;
    }
    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        color: #4F4F4F;
    }
    .reset {
        text-align: right;
        color: #07C0CA;
        span {
            font-size: 24px;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }
}

.button {
    box-sizing: border-box !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    margin: 0px !important;
}

.buttonEnabled {
    @extend .button;
    background-color: #07c0ca !important;
    border-radius: 2px !important;
    border: 2px solid #07C0CA !important;
}

.buttonDisabled {
    @extend .button;
    background-color: #D6D8E1 !important;
    box-shadow: none !important;
}

.filters{
    padding-top: 30px;
}

.filter {
    .form {
        margin-bottom: 30px !important;
        border: 1px solid #D6D8E1 !important;
        box-sizing: border-box;
        border-radius: 4px !important;
        padding: 0 !important;
        cursor: pointer;
    }
    .title {
        max-width: 270px;
        display: inline-block;
        padding: 8px 18px 8px 18px;
        text-align: left;
        color: #4F4F4F;
        font-size: 14px;
        span {
            color: #07C0CA;
            position: relative;
            top: -4px !important;
        }
    }
    .toggle {
        display: inline-block;
        width: 40px;
        text-align: right;
        padding-right: 21px;
        color: #07C0CA;
        font-size: 24px;
        float: right;
        span {
            position: relative;
            top: 6px;
        }
    }
    .expanded{
       transform: rotate(180deg);
    }
    .options {
        .selectItem:last-child{
            padding-bottom: 32px;
        }
        .selectItem:first-child{
            padding-top: 22px;
        }
        box-shadow: 1px 1px 15px rgba(120, 126, 156, 0.2);
        border-radius: 8px;
        padding-left: 3px;
        margin-bottom: 30px;
    }
}

.selectItem {
    padding-bottom: 15px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    cursor: pointer;
    .box {
        font-size: 24px;
        max-width: 40px;
        cursor: pointer;
        color: #D6D8E1;
        .selected {
            color: #07C0CA;
        }
    }
    .text {
        font-size: 14px;
    }
}

.label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #31364C;
    margin-bottom: 12px;
}



