.logo {
  display: block;
  margin: 30px auto 30px;
  width: 100%;
}

.label {
  font-size: 12px;
  color: #52576F;
  margin-bottom: 10px;
}

.bankLabel {
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 140%;
  color: #D6D8E1;
}

.bankName {
  margin-top: -10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #07C0CA;
}

.button {
  border-radius: 2px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  margin: 0 !important;
  margin-bottom: 15px !important;
  border: 2px solid #07C0CA !important;
  box-shadow: none !important;
}

button:hover {
  box-shadow: none !important;
}

.continueButton {
  @extend .button;
  margin-top: 15px !important;
  background-color: #07c0ca !important;
}

.disabledButton {
  @extend .continueButton;
  background-color: #D6D8E1 !important;
  border: 2px solid #D6D8E1 !important;
  cursor: default !important;
}

.cancelButton {
  @extend .button;
  background-color: white !important;
  color: #07c0ca !important;
}
