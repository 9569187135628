.transaction {
  margin-bottom: 0 !important;
  border-top: 1px solid #D6D8E1;
  padding: 20px 30px;
  cursor: pointer;
    table {
      width: 100%;
      tbody {
        td {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 24px;          
          letter-spacing: -0.34px;
          color: #52576F;
          padding-top: 8px;
        }
      }
    }
  
    td {
      border: 0px !important;
      box-sizing: border-box;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
  
      &.date {
        text-align: right;
        width: 70px;
        font-size: 12px;
        font-weight: normal;
      }
  
      &.name {
        text-align: left;
        font-weight: bold;
        max-width: 100px;
        padding-right: 2px;
      }
  
      &.amount {
        text-align: right;
        width: 70px;
      }
  
      &.status {
        text-align: left;
        font-weight: 500;
        span {
          text-transform: uppercase;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 12px;
          padding: 2px 8px;
          border: 1px solid #226F54;
          border-radius: 20px;
          color: #FFFFFF;
          &.default {
            border-color: #07C0CA;
            background-color: #07C0CA;
          }
          &.initiated {
            border-color: #FFAE1A;
            background-color: #FFAE1A;
          }
          &.declined {
            border-color: #FE6E65;
            background-color: #FE6E65;
          }
          &.expired {
            border-color: #787E9C;
            background-color: #787E9C;
          }
          &.refunded {
            border-color: #52576F;
            background-color: #52576F;
          }
        }
      }
    }
  }
