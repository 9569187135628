.header {
  border-bottom: 1px solid #CECECE;
  margin-right: -30px !important;
  margin-left: -30px !important;
  padding: 16px 15px 20px 15px;
  .close {
    font-size: 24px;
    max-height: 20px;
    color: #07C0CA;
    cursor: pointer;
  }
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #4F4F4F;
  }
  .reset {
    text-align: right;
    color: #07C0CA;
    span {
      font-size: 24px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
