.infoWrapper {
  display: grid;
  grid-template-columns: 20px 1fr;
}
.info {
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 3;
}
.job {
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}
.loan {
  grid-column-start: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}