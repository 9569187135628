.option {
  box-shadow: 1px 1px 15px rgba(120, 126, 156, 0.2);
  border-radius: 8px;
  padding: 16px;
  color: #31364c;
  display: flex;
  cursor: pointer;
  margin-bottom: 15px;

  .info {
    flex: 1;
  }

  .control {
    color: #07C0CA;
    span{
      position: relative;
      top: 20%;
    }
  }

}

