.label {
    font-size: 14px;
    font-weight: bold;
    padding-top: 9px;
    padding-bottom: 9px;
    img {
        margin-right: 10px;
        vertical-align: text-bottom;
        width: 20px;
        height: 20px;
    }
}

.user {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #31364C;
    margin-top: 30px;
    margin-bottom: 30px;
    span {
        display: block;
        margin-bottom: 15px;
    }
    .role {
        font-weight: normal;
        font-size: 12px;
        color: #52576F;
    }
}
