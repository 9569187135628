.header {
    border-top: 1px solid #D6D8E1;
    padding: 16px;
    font-size: 16px;
    text-align: left;
    color: #4F4F4F;
    font-weight:bold;
    span {
        color: #07C0CA;
        position: relative;
        top: -4px !important;        
    }
}

.info {
    font-size: 14px !important;
    padding-left: 16px;
    padding-right: 16px;
    span {
        font-size: 14px !important;
        margin-right: 6px;
        color: #837f7f;
        position: relative;
        top: 2px;        
    }
}

.sub {
    font-size: 14px !important;
    padding-left: 32px;
    padding-right: 16px;
    color: #787E9C;
    span:last-child {
        float: right;
    }
}

.cardHeader {
    border-bottom: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.cardBody {
    .info:last-child{
        padding-bottom: 16px !important;
    }
}