.content {
  margin-bottom: 25px;
}

.header {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.27px;
    color: #31364C;
  }

  .subtitle {
    margin-top: 15px;
    color: #52576F;
    font-size: 14px;
  }
}

.user_info {
  display: grid;
  grid-template-columns: 20px 1fr;
  margin-bottom: 30px;
}

.info {
  font-size: 14px;
  color: #52576F;
  margin-bottom: 12px;

  span {
    font-size: 14px;
    margin-right: 11px;
    color: #07C0CA;
    position: relative;
    top: 2px;
  }
}

.name_ico {
  @extend .info;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 2;
}

.name {
  @extend .info;
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.phone_ico {
  @extend .info;
  grid-column-start: 1;
  grid-row-start: 3;
  grid-row-end: 3;
}

.phone {
  @extend .info;
  grid-column-start: 2;
  grid-row-start: 3;
  grid-row-end: 3;
}

.email_ico {
  @extend .info;
  grid-column-start: 1;
  grid-row-start: 4;
  grid-row-end: 4;
}

.email {
  @extend .info;
  grid-column-start: 2;
  grid-row-start: 4;
  grid-row-end: 4;
}

.permissions {

  display: grid;
  margin-bottom: 17px;
  max-height: 24px;

  .standard {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .admin {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }

}

.subscription {

  display: grid;
  margin-bottom: 30px;
  max-height: 24px;
  grid-gap: 10px;

  .subscription_checkbox {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .subscription_text {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    font-size: 10px;
    line-height: 150%;
  }
}

.error_wrapper {
  margin-bottom: 30px;
}

.confirmation_message {
  font-size: 14px;
  text-align: center;
  color: #52576F;
  margin-bottom: 30px;
}