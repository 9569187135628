.text_button {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  background-color: #ffffff !important;
  text-align: center;
  cursor: pointer;
  color: #07c0ca !important;
}

.content {
  margin-bottom: 25px;
}

.header {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.27px;
    color: #31364C;
  }
  .subtitle {
    margin-top: 15px;
    color: #52576F;
    font-size: 14px;
    overflow: hidden;
  }
}

.modal_text {
  text-align: center;

  .logo {
    margin-left: auto;
    margin-right: auto;
    margin-top: -15px;
    margin-bottom: 32px;
  }

  h4 {
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0.27px;
    margin-bottom: 15px;
    color: #31364c;
  }
  div {
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    color: #52576F;
    line-height: 140%;
    span {
      color: #0aced9;
    }
  }
}

.support {
  margin-top: 5px;
  font-size: 12px;
  color: #52576F;
}

.text_button {
  font-size: 14px !important;
  font-weight: bold !important;
  background-color: #ffffff !important;
  text-align: center;
  cursor: pointer;
  color: #07c0ca !important;
}