.header {
  border-top: 1px solid #D6D8E1;
  padding: 16px;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  color: #31364C;
  span {
    color: #07C0CA;
    position: relative;
    top: -4px !important;
  }
}

.info {
  font-size: 14px !important;
  padding-left: 16px;
  padding-right: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  color: #52576F;
  margin-bottom: 10px;
  span {
    font-size: 14px !important;
    margin-right: 12px;
    color: #07C0CA;
    position: relative;
    top: 2px;
  }
}

.sub {
  font-size: 14px !important;
  padding-left: 32px;
  padding-right: 16px;
  color: #787E9C;
  span:last-child {
    float: right;
  }
}

.cardHeader {
  border-bottom: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.cardBody {
  .info:last-child{
    padding-bottom: 16px !important;
  }
}