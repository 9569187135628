.content {
  margin-bottom: 25px;
}

.header {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;

  img {
    margin-top: 30px;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.27px;
    color: #31364C;
  }
}

.names {
  display: inline-flex;
  .firstName {
    min-width: 150px;
    margin-right: 15px;
  }
}

.permissions {

  display: grid;
  margin-bottom: 17px;
  max-height: 24px;

  .standard {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .admin {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }

}

.error_banner_wrapper {
  margin-bottom: 30px;
}

.description {
  text-align: center;
  font-size: 14px;
  color: #52576F;
}