.button {
  box-sizing: border-box !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  margin: 0px !important;
}

.buttonEnabled {
  @extend .button;
  background-color: #07c0ca !important;
  border-radius: 2px !important;
  border: 2px solid #07C0CA !important;
}

.buttonDisabled {
  @extend .button;
  background-color: #D6D8E1 !important;
  box-shadow: none !important;
}
