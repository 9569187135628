.merchant {
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.27px;
  color: #31364C;
  margin-top: 30px;
  margin-bottom: 30px;
}

.option {
  padding-top: 5px;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  color: #31364C;
}