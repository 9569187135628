.controls {
  margin-top: 15px;
  margin-bottom: 30px;
  .filter{
    display: inline-block;
    span {
      font-size: 18px;
      vertical-align: middle;
      color: #07C0CA;
      margin-left: 5px;
    }
  }
  .filter_img {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }
  .create {
    display: inline-block;
    float: right;
  }
  .create_img{
    cursor: pointer;
    margin-right: 5px;
  }
  .label {
    font-weight: 600;
    font-size: 14px;
    color: #07c0ca;
    line-height: 1.57;
  }
}