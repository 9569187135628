.navbar {
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    border-bottom: 1px solid #D6D8E1;
    opacity: 1;
    max-height: 57px;
    padding-top: 15px;
    padding-bottom: 20px;
    margin-right: -30px !important;
    margin-left: -30px !important;
    .back_arrow {
        margin-left: 15px;
        cursor: pointer;
    }
}

.title {
    font-size: 14px;
    font-weight: bold;
    min-width: 200px;
    text-align: center;
}

.hamburger {
    display: block;
    position: relative;
    right: 20px;
    top: 5px;
    float: right;
    z-index: 10;
    -webkit-user-select: none;
    user-select: none;
    input {
        display: block;
        width: 28px;
        height: 24px;
        position: absolute;
        top: -5px;
        left: -5px;
        cursor: pointer;
        opacity: 0; /* hide this */
        z-index: 11; /* and place it over the hamburger */
        -webkit-touch-callout: none;
        &:checked {
            ~ .hmbrg {
                &:nth-last-child(2) {
                    opacity: 1;
                    transform: translate(4px, -12px) rotate(45deg);
                }
                &:nth-last-child(3) {
                    opacity: 0;
                    transform: rotate(0deg) scale(0.2, 0.2);
                }
                &:nth-last-child(4) {
                    opacity: 1;
                    transform: translate(2px, 12.5px) rotate(-45deg);
                }
            }
            ~ .menu {
                transform: none;
                opacity: 1;
            }

        }
    }
    .hmbrg {
        display: block;
        width: 22px;
        height: 2px;
        margin-bottom: 4px;
        position: relative;
        background: #07c0ca;
        transform-origin: 2px 0px;
        border-radius: 8px;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    opacity 0.55s ease;
    }
}

.menu {
    position: absolute !important;
    padding: 30px 28px 40px;
    line-height: normal;
    width: 315px;
    max-width: 315px;
    top: 46px;
    right: -5px;
    z-index: 5;
    background: #FFFFFF;
    box-shadow: 1px 1px 15px rgba(120, 126, 156, 0.2);
    border-radius: 8px;
    transform: translate(0, -120%);
    opacity: 0;
    transition: transform 0.6s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.6s ease;
    .divider {
        padding-top: 20px;
        border-bottom: 1px solid #D6D8E1;
    }
    .item {
        padding-top: 15px;
        cursor: pointer;
        &:hover {
            color: #07C0CA;
        }
    }
}

.icon{
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-bottom;
    max-width: 18px !important;
}

.account {
    max-width: 207px;
    vertical-align: text-top;
    display: inline-block;
    .merchant {
        font-size: 16px;
        font-weight: bold
    }
    .user {
        font-size: 12px;
        font-weight: normal;
    }
}

.clickable {
    cursor: pointer;
    &:hover {
        color: #07C0CA;
    }
}

.cardHeader {
    border-bottom: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.accountHeader {
    font-size: 16px;
    text-align: left;
    color: #07C0CA;
    .arrow {
        position: absolute;
        max-width: 24px;
        right: -8px;
        display: inline-block;
    }
}

.cardBody {
    padding-bottom: 0px !important;
    .account{
        margin-left: 28px;
        margin-top: 15px;
    }
}

.logo {
    display: block;
    margin-right: auto;
    margin-left: auto;
}
