.header {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;

  .title {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 30px;
  }

  img {
    width: 60px;
    height: 60px;
  }
}

.content {
  display: grid;
  grid-template-columns: 20px 1fr;
  margin-bottom: 25px;
}

.info {
  font-size: 14px;
  color: #52576F;
  margin-bottom: 12px;
  span {
    font-size: 14px;
    margin-right: 11px;
    color: #07C0CA;
    position: relative;
    top: 2px;
  }
}

.name_ico {
  @extend .info;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 2;
}
.name {
  @extend .info;
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.street_ico {
  @extend .info;
  grid-column-start: 1;
  grid-row-start: 2;
  grid-row-end: 2;
}

.street {
  @extend .info;
  grid-column-start: 2;
  grid-row-start: 2;
  grid-row-end: 2;
}

.phone_ico {
  @extend .info;
  grid-column-start: 1;
  grid-row-start: 3;
  grid-row-end: 3;
}

.phone {
  @extend .info;
  grid-column-start: 2;
  grid-row-start: 3;
  grid-row-end: 3;
}

.email_ico {
  @extend .info;
  grid-column-start: 1;
  grid-row-start: 4;
  grid-row-end: 4;
}

.email {
  @extend .info;
  grid-column-start: 2;
  grid-row-start: 4;
  grid-row-end: 4;
}

.ein_ico {
  @extend .info;
  grid-column-start: 1;
  grid-row-start: 5;
  grid-row-end: 5;
}

.ein {
  @extend .info;
  grid-column-start: 2;
  grid-row-start: 5;
  grid-row-end: 5;
}

.acc_ico {
  @extend .info;
  grid-column-start: 1;
  grid-row-start: 6;
  grid-row-end: 6;
}

.acc {
  @extend .info;
  grid-column-start: 2;
  grid-row-start: 6;
  grid-row-end: 6;
}
