.wisetack_button {
  border-radius: 2px !important;
  padding-top: 18px !important;
  padding-bottom: 19px !important;
  font-size: 14px !important;
  line-height: 17px !important;
  font-weight: bold !important;
  box-sizing: border-box !important;
  box-shadow: none !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  margin-bottom: 15px !important;
}