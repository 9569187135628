.header {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;

  .title {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 30px;
  }

  img {
    width: 60px;
    height: 60px;
  }
}

.users {
  border-bottom: 1px solid #E0E0E0;
  margin-bottom: 25px;
  margin-left: -30px !important;
  margin-right: -30px !important;
}

.user_row {
  padding: 20px 20px 20px 15px;
  border-top: 1px solid #E0E0E0;
  align-items: center;
  margin-right: 0 !important;
  margin-left: 0 !important;

  .text {
    font-size: 14px;
    font-weight: 600;
    color: #31364C;
    .role {
      font-weight: normal;
      font-size: 12px;
    }
  }
  .edit {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #07C0CA;
    cursor: pointer;
    max-width: 60px;
  }
  .edit:hover {
    color: #006f7b;
  }
}
