.logo {
  display: block;
  margin: 10px auto 30px;
}

.button {
  border-radius: 2px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  margin: 0 !important;
  margin-bottom: 15px !important;
  border: 2px solid #07C0CA !important;
  box-shadow: none !important;
}

button:hover {
  box-shadow: none !important;
}

.submitButton {
  @extend .button;
  margin-top: 15px !important;
  background-color: #07c0ca !important;
}

.editButton {
  @extend .button;
  background-color: white !important;
  color: #07c0ca !important;
}

.panel {
  background: #FFFFFF;
  box-shadow: 1px 1px 15px rgba(120, 126, 156, 0.2);
  border-radius: 8px;
  padding: 30px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.label {
  font-size: 12px;
  line-height: 140%;
  color: #52576F;
  margin-bottom: 5px;
  margin-top: 15px;
}

.value {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #31364C;
}

.subtype {
  @extend .value;
  text-transform: lowercase;
}

.subtype::first-letter {
  text-transform: uppercase;
}
